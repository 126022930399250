// src/pages/CustomEcommerceDevelopment.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import TailoredDesignIcon  from "images/tailored-design-icon.svg";
import FeatureRichPlatformsIcon  from "images/feature-rich-platform-icon.svg";
import ScalableSolutionsIcon  from "images/scalable-solutions-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Custom E-commerce Development"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Custom E-commerce Development</Subheading>}
          heading={
            <Heading>
              Tailored Online Stores for Your <span tw="text-primary-500">Business Growth</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1523837157348-ffbdaccfc7de?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in creating custom e-commerce solutions that perfectly align with your brand identity and business objectives. Our expert team develops robust, scalable, and user-friendly online stores that drive engagement and boost sales. Whether you're looking to build a new e-commerce platform or enhance an existing one, we have the skills and experience to deliver exceptional results.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Custom E-commerce Development</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1523837157348-ffbdaccfc7de?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses tailored design, feature-rich platforms, and scalable solutions. Our team collaborates closely with you throughout the development process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Custom E-commerce Development Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Approach</Subheading>}
          heading="Comprehensive Custom E-commerce Development"
          description="We follow a meticulous process to ensure your custom e-commerce platform meets and exceeds your expectations."
          cards={[
            {
              imageSrc: TailoredDesignIcon,
              title: "Tailored Design",
              description:
                "We create unique, brand-aligned designs that provide an engaging shopping experience for your customers.",
              url: "/custom-ecommerce-development/tailored-design",
            },
            {
              imageSrc: FeatureRichPlatformsIcon,
              title: "Feature-Rich Platforms",
              description:
                "Our e-commerce solutions come loaded with features to enhance user experience and streamline operations.",
              url: "/custom-ecommerce-development/feature-rich-platforms",
            },
            {
              imageSrc: ScalableSolutionsIcon,
              title: "Scalable Solutions",
              description:
                "We build flexible, scalable platforms that can grow and adapt as your business expands.",
              url: "/custom-ecommerce-development/scalable-solutions",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>

  );
};
