// src/pages/SocialMediaBranding.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import ContentCreationIcon from "images/content-creation-icon.avif";
import CommunityManagementIcon from "images/community-management-icon.avif";
import PaidSocialAdvertisingIcon from "images/paid-social-advertising-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Social Media Branding"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Social Media Marketing</Subheading>}
          heading={
            <Heading>
              Engage Your Audience on <span tw="text-primary-500">Social Platforms</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1593642532973-d31b6557fa68?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in building and enhancing your brand's presence across various social media platforms. Our expert team leverages the latest trends and tools to create strategies that resonate with your target audience, fostering engagement and driving growth.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={true}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Data-Driven Social Media Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in analytics and tailored to your unique business objectives. We analyze your audience's behavior, optimize your content strategy, and utilize advanced tools to monitor performance. From scheduling posts to engaging with your community, we handle every aspect to ensure your social media efforts yield tangible results.
            </Description>
          }
          textOnLeft={false}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Social Media Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Social Media Services</Subheading>}
          heading="Comprehensive Social Media Solutions"
          description="MilaajBrandset offers a full suite of social media branding services designed to elevate your brand's online presence and drive engagement. Our services are tailored to meet your unique needs, ensuring a cohesive and effective strategy."
          cards={[
            {
              imageSrc: ContentCreationIcon,
              title: "Content Creation",
              description:
                "We create engaging, platform-specific content to captivate your audience and drive engagement.",
              url: "/social-media-branding/content-creation",
            },
            {
              imageSrc: CommunityManagementIcon,
              title: "Community Management",
              description:
                "We manage your social media communities, fostering relationships and increasing brand loyalty.",
              url: "/social-media-branding/community-management",
            },
            {
              imageSrc: PaidSocialAdvertisingIcon,
              title: "Paid Social Advertising",
              description:
                "We develop and manage targeted social media ad campaigns to reach your ideal audience.",
              url: "/social-media-branding/paid-social-advertising",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
