// src/pages/ResponsiveWebDesign.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import MobileFirstDesignIcon  from "images/mobile-first-design-icon.svg";
import TabletOptimizationIcon  from "images/tablet-optimization-icon.svg";
import DesktopExcellenceIcon  from "images/desktop-excellence-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Responsive Web Design"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Responsive Web Design</Subheading>}
          heading={
            <Heading>
              Optimal Viewing Experience Across All <span tw="text-primary-500">Devices</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/learn-more" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1554260570-9140fd3b7614?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we ensure your website looks and functions perfectly on all devices. Our responsive web design approach guarantees an optimal user experience whether your site is viewed on a desktop, tablet, or smartphone. We combine aesthetics with functionality to deliver websites that are both beautiful and highly performant.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Device-Agnostic Web Design</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1554260570-9140fd3b7614?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              We employ cutting-edge techniques to create websites that adapt seamlessly to any screen size. Our process involves thorough analysis of user behavior, strategic layout planning, and rigorous testing to ensure that your website delivers a consistent and engaging experience across all devices. From fluid grids and flexible images to media queries and responsive frameworks, we leverage the best tools and methodologies to achieve exceptional results.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Responsive Web Design Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Services</Subheading>}
          heading="Comprehensive Responsive Web Solutions"
          description="MilaajBrandset offers a full suite of Responsive Web Design services designed to elevate your online presence and enhance user engagement. Our services are tailored to meet your unique needs, ensuring a cohesive and effective web strategy."
          cards={[
            {
              imageSrc: MobileFirstDesignIcon,
              title: "Mobile-First Design",
              description:
                "We prioritize mobile design to ensure your site performs flawlessly on smartphones and smaller screens.",
              url: "/responsive-web-design/mobile-first-design",
            },
            {
              imageSrc: TabletOptimizationIcon,
              title: "Tablet Optimization",
              description:
                "Our designs adapt perfectly to tablet screens, providing an excellent user experience on these devices.",
              url: "/responsive-web-design/tablet-optimization",
            },
            {
              imageSrc: DesktopExcellenceIcon,
              title: "Desktop Excellence",
              description:
                "We ensure your website looks stunning and functions smoothly on larger desktop screens.",
              url: "/responsive-web-design/desktop-excellence",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
