// src/pages/SEO.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import OnPageSEOIcon from "images/on-page-seo-icon.svg";
import OffPageSEOIcon from "images/off-page-seo-icon.svg";
import TechnicalSEOIcon from "images/technical-seo-icon.svg";
import AnalyticsIcon from "images/seo-analytics-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Search Engine Optimization"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>SEO Services</Subheading>}
          heading={
            <Heading>
              Boost Your <span tw="text-primary-500">Search Engine Rankings</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1593642532973-d31b6557fa68?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in enhancing your website's visibility and authority. Our comprehensive SEO services are designed to improve your search engine rankings, drive organic traffic, and boost your online presence. Whether you're looking to optimize your existing content or build a robust SEO strategy from scratch, our team of experts is here to help.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Data-Driven SEO Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in data and focused on results. We conduct thorough keyword research, analyze your competitors, and assess your website's current performance to develop a tailored SEO strategy. From on-page optimization and technical SEO to off-page efforts and continuous monitoring, we ensure every aspect of your SEO is meticulously handled to achieve sustainable growth.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our SEO Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our SEO Services</Subheading>}
          heading="Comprehensive SEO Solutions"
          description="MilaajBrandset offers a full suite of SEO services designed to elevate your website's search engine performance and drive organic growth. Our services are tailored to meet your unique needs, ensuring a cohesive and effective SEO strategy."
          cards={[
            {
              imageSrc: OnPageSEOIcon,
              title: "On-Page SEO",
              description:
                "We optimize your website's content, structure, and HTML elements to improve search engine rankings.",
              url: "/seo/on-page",
            },
            {
              imageSrc: OffPageSEOIcon,
              title: "Off-Page SEO",
              description:
                "We build high-quality backlinks and improve your website's authority to boost your search engine visibility.",
              url: "/seo/off-page",
            },
            {
              imageSrc: TechnicalSEOIcon,
              title: "Technical SEO",
              description:
                "We ensure your website is technically optimized for search engines, improving crawlability and indexability.",
              url: "/seo/technical",
            },
            {
              imageSrc: AnalyticsIcon,
              title: "SEO Analytics & Reporting",
              description:
                "We provide detailed analytics and reports to measure the effectiveness of your SEO campaigns.",
              url: "/seo/analytics-reporting",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>

  );
};
