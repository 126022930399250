import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";
import Features from "Components/Features/ThreeColumnPages";

// import CampaignStrategyIcon from "images/campaign-strategy-icon.svg";
// import EmailDesignIcon from "images/email-design-icon.svg";
// import AutomationIcon from "images/automation-icon.svg";
// import SegmentationIcon from "images/segmentation-icon.svg";
// import AnalyticsIcon from "images/analytics-icon.svg";
// import AIBasedIcon from "images/ai-based-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Email Marketing"} />
        
        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Email Marketing</Subheading>}
          heading={
            <Heading>Reach Your Customers <span tw="text-primary-500">Directly</span></Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1557200134-90327ee9fafa?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in creating impactful email marketing campaigns that connect you with your audience. Our expert team leverages the latest tools and strategies to ensure your messages are delivered effectively, driving engagement and conversions.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Data-Driven Email Marketing Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in data and focused on results. We analyze your target audience, optimize your email content, and utilize advanced segmentation to ensure your campaigns resonate with each recipient. From crafting compelling subject lines to automating follow-ups, we handle every aspect to maximize your email marketing success.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Email Marketing Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Email Marketing Services</Subheading>}
          heading="Comprehensive Email Marketing Solutions"
          description="MilaajBrandset offers a full suite of email marketing services designed to elevate your brand's communication and drive measurable results. Our services are tailored to meet your unique needs, ensuring a cohesive and effective email strategy."
          cards={[
            {
              imageSrc: "CampaignStrategyIconLink",
              title: "Campaign Strategy",
              description:
                "We develop targeted email marketing strategies to engage your audience and drive conversions.",
            },
            {
              imageSrc: "EmailDesignIconLink",
              title: "Email Design & Content",
              description:
                "We create visually appealing emails with compelling content that resonates with your audience.",
            },
            {
              imageSrc: "AutomationIconLink",
              title: "Automation & Segmentation",
              description:
                "We set up automated email flows and segment your audience for personalized messaging.",
            },
            {
              imageSrc: "SegmentationIconLink",
              title: "Advanced Segmentation",
              description:
                "We analyze and segment your audience to deliver highly targeted and relevant emails.",
            },
            {
              imageSrc: "AnalyticsIconLink",
              title: "Analytics & Reporting",
              description:
                "We provide detailed analytics and reports to measure the effectiveness of your email campaigns.",
            },
            {
              imageSrc: "AIBasedIconLink+",
              title: "AI-Based Personalization",
              description:
                "Utilize AI to personalize email content and improve engagement rates.",
            },
          ]}
          linkText=""
        />
      </AnimationRevealPage>

      
  );
};
