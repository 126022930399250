// src/pages/CustomApiDevelopment.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import TailoredSolutionsIcon from "images/tailored-solutions-icon.svg";
import SeamlessIntegrationIcon from "images/seamless-integration-icon.svg";
import ScalabilityPerformanceIcon from "images/scalability-performance-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Custom API Development"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Custom API Development</Subheading>}
          heading={
            <Heading>
              Tailored APIs for Your <span tw="text-primary-500">Unique Business Needs</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in developing custom APIs that perfectly align with your business requirements. Our expert team creates robust, scalable, and secure APIs that seamlessly integrate with your existing systems, enhancing your operational efficiency and driving innovation.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Data-Driven Custom API Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in data and focused on results. We analyze your business processes, identify integration points, and develop a customized API strategy that aligns with your goals. From initial design and development to testing and deployment, we ensure your APIs are optimized for performance, scalability, and security.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Custom API Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Custom API Services</Subheading>}
          heading="Comprehensive Custom API Solutions"
          description="MilaajBrandset offers a full suite of Custom API Development services designed to elevate your business's operational capabilities and drive innovation. Our services are tailored to meet your unique needs, ensuring a cohesive and effective API strategy."
          cards={[
            {
              imageSrc: TailoredSolutionsIcon,
              title: "Tailored Solutions",
              description:
                "We design and develop APIs that are specifically tailored to your unique business processes and requirements.",
              url: "/custom-api-development/tailored-solutions",
            },
            {
              imageSrc: SeamlessIntegrationIcon,
              title: "Seamless Integration",
              description:
                "Our APIs integrate smoothly with your existing systems, ensuring efficient data flow and communication.",
              url: "/custom-api-development/seamless-integration",
            },
            {
              imageSrc: ScalabilityPerformanceIcon,
              title: "Scalability & Performance",
              description:
                "We build APIs with scalability in mind, ensuring they can handle growing data volumes and user bases.",
              url: "/custom-api-development/scalability-performance",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
