// src/pages/CustomWebDevelopment.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import ExpertCodingIcon  from "images/expert-coding-icon.svg";
import StunningDesignIcon  from "images/stunning-design-icon.svg";
import OngoingSupportIcon  from "images/ongoing-support-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Custom Web Development"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Custom Web Development</Subheading>}
          heading={
            <Heading>
              Tailored Websites for Your <span tw="text-primary-500">Unique Business Needs</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in creating custom websites that perfectly align with your brand identity and business objectives. Our expert team develops robust, scalable, and user-friendly websites that drive engagement and conversions. Whether you're looking to build a new website or revamp an existing one, we have the skills and experience to deliver exceptional results.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Custom Web Development</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses user experience (UX) design, responsive development, and thorough testing to ensure your website performs flawlessly across all devices. Our team collaborates closely with you throughout the development process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Custom Web Development Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Services</Subheading>}
          heading="Comprehensive Custom Web Solutions"
          description="MilaajBrandset offers a full suite of Custom Web Development services designed to elevate your online presence and drive business growth. Our services are tailored to meet your unique needs, ensuring a cohesive and effective web strategy."
          cards={[
            {
              imageSrc: ExpertCodingIcon,
              title: "Expert Coding",
              description:
                "Our developers use the latest technologies and best practices to build high-performance, secure websites.",
              url: "/custom-web-development/expert-coding",
            },
            {
              imageSrc: StunningDesignIcon,
              title: "Stunning Design",
              description:
                "We create visually appealing, intuitive interfaces that reflect your brand and engage your audience.",
              url: "/custom-web-development/stunning-design",
            },
            {
              imageSrc: OngoingSupportIcon,
              title: "Ongoing Support",
              description:
                "We provide continuous support and maintenance to ensure your website remains up-to-date and optimized.",
              url: "/custom-web-development/ongoing-support",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
