import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from "Components/headers/PageHeader";
import Features from "Components/Features/ThreeColumnPages";

import DM1 from "images/dM-1.avif";
import DM2 from "images/dM-2.webp";
import DM3 from "images/dM-3.webp";

import DM5 from "images/dM-5.avif"
import DM6 from "images/dM-6.avif"
import DM7 from "images/dM-7.avif"

import SeoIconImage from "images/seo-icon.svg";
import ContentIconImage from "images/content-icon.avif";
import AnalyticsIconImage from "images/analytics-icon.webp";
import SocialMediaIconImage from "images/social-media-icon.svg";
import EmailMarketingIconImage from "images/email-marketing-icon.svg";
import SocialMediaBrandingIcon from "images/social-media-branding-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header from={"Digital Marketing"} />
      <MainFeature1
        subheading={<Subheading>Digital Marketing Experts</Subheading>}
        heading={
          <Heading>Boost Your Online Presence with MilaajBrandset</Heading>
        }
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/learn-more" // Add the URL for the button
        imageSrcs={[DM1, DM3, DM2]}
        description={
          <Description>
            At MilaajBrandset, we specialize in enhancing your digital
            footprint. Our team of experts utilizes cutting-edge techniques to
            ensure your brand stands out in the crowded digital landscape. With
            our comprehensive digital marketing services, we help you attract,
            engage, and convert more leads.
          </Description>
        }
        widths={["55%", "60%", "70%"]}
        heights={["45%", "60%", "35%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 35%; top: 20%;",
          "right: 30%; top: 10%;",
          "left: 32%; bottom: 20%;",
        ]} 
      />

      <MainFeature1
        subheading={<Subheading>Our Approach</Subheading>}
        heading={<Heading>Data-Driven Digital Marketing Strategies</Heading>}
        buttonRounded={false}
        primaryButtonText="Learn More"
        primaryButtonUrl="/learn-more-approach"
        imageSrcs={[
         DM6,
         DM5,
         DM7
        ]}
        description={
          <Description>
            Our approach is rooted in data and driven by results. We leverage
            the power of analytics to craft personalized marketing strategies
            that align with your business goals. From SEO and social media
            marketing to email campaigns and detailed analytics, we cover all
            aspects to ensure your digital success.
          </Description>
        }
        textOnLeft={true}
        widths={["50%", "55%", "70%"]}
        heights={["60%", "70%", "40%"]}
        imageColumnStyle={`height: 400px;`} // Adjusting height as required
        imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
        imagePositions={[
          "left: 13%; top: 20%;",
          "right: 0; top: 10%;",
          "left: 10%; bottom: 0;",
        ]} // Custom positions for each image
      />

      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Digital Marketing Solutions"
        description="MilaajBrandset offers a full suite of digital marketing services toelevate your brand's online presence and drive measurable results.
            Our services are designed to cater to all your digital marketing
            needs, ensuring a cohesive and effective strategy."
        cards={[
          {
            imageSrc: SeoIconImage,
            title: "Search Engine Optimization",
            description:
              "Improve your website's visibility in search results with our expert SEO strategies.",
            // url: "/seo",
          },
          {
            imageSrc: SocialMediaIconImage,
            title: "Social Media Marketing",
            description:
              "Engage your audience on popular social platforms with our tailored strategies.",
            // url: "/social-media-marketing",
          },
          {
            imageSrc: AnalyticsIconImage,
            title: "Google Analytics Services",
            description:
              "Make data-driven decisions with our comprehensive Google Analytics services.",
            // url: "/google-analytics",
          },
          {
            imageSrc: SocialMediaBrandingIcon,
            title: "Social Media Branding",
            description:
              "Create a strong and consistent brand presence across all social media platforms.",
            // url: "/social-media-branding",
          },
          {
            imageSrc: EmailMarketingIconImage,
            title: "Email Marketing",
            description:
              "Reach your customers directly with personalized email campaigns.",
            // url: "/email-marketing",
          },
          {
            imageSrc: ContentIconImage,
            title: "Content Marketing",
            description:
              "Engage your audience with compelling, SEO-optimized content that drives conversions.",
            // url: "/content-marketing",
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};
