// src/pages/UserResearchAnalysis.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import UserResearchIcon  from "images/user-research-icon.svg";
import DataAnalysisIcon  from "images/data-analysis-icon.webp";
import ActionableInsightsIcon  from "images/actionable-insights-icon.webp";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const UserResearchAnalysis = () => {
  return (
      <AnimationRevealPage>
        <Header from={"User Research & Analysis"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>User Research & Analysis</Subheading>}
          heading={
            <Heading>
              In-Depth <span tw="text-primary-500">User Research</span> and Analysis
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we conduct thorough user research to understand your target audience and their needs. Our research informs all our design decisions, ensuring that the user interface (UI) we create is tailored to meet user expectations and provide an exceptional user experience.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Research & Analysis Services</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1586717791821-3f44a563fa4c?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses user research, data analysis, and actionable insights. Our team collaborates closely with you throughout the process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our User Research & Analysis Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Testing Process</Subheading>}
          heading={
            <Heading>
              Comprehensive <span tw="text-primary-500">Research & Analysis</span> Services
            </Heading>
          }
          description="We offer a range of research and analysis services to provide deep insights into your users, helping you create a user interface (UI) that truly resonates with your audience."
          cards={[
            {
              imageSrc: UserResearchIcon,
              title: "User Research",
              description:
                "We conduct in-depth user research to understand your target audience and their needs, informing our design decisions.",
              url: "/user-research-analysis/user-research",
            },
            {
              imageSrc: DataAnalysisIcon,
              title: "Data Analysis",
              description:
                "We analyze the collected data to extract meaningful insights and identify key trends.",
              url: "/user-research-analysis/data-analysis",
            },
            {
              imageSrc: ActionableInsightsIcon,
              title: "Actionable Insights",
              description:
                "We provide actionable insights to help you make informed decisions and improve your product's user experience.",
              url: "/user-research-analysis/actionable-insights",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};

export default UserResearchAnalysis;
