import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "Components/headers/header";
import Footer from "Components/Footer/MiniCenteredFooter";
import styled from "styled-components";
import ContactUsForm from "Components/Forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "Components/Cards/ThreeColContactDetails.js";

// import { ReactComponent as EmailIcon } from "images/mail.svg";
// import { ReactComponent as PhoneIcon } from "images/bxs-phone.svg";

// Styled Components
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;

// StyledLink removes underline and styles the link
const StyledLink = styled.a`
  ${tw`no-underline text-sm text-gray-500 hover:text-customPurple transition-colors duration-300`}
`;

// Email and Phone containers using flex to align icon and text
const Email = styled.span`
  ${tw`flex items-center text-sm text-gray-500`}
`;

const Phone = styled.span`
  ${tw`flex items-center text-sm text-gray-500`}
`;

const StyledHeader = styled(Header)`
  ${tw`text-black`}
`;

const ContactForm = styled(ContactUsForm)`
  ${tw`bg-customGreyLight`}
`;

// Contact Information
const emailLink = "mailto:support@milaajbrandset.com";
const phoneNumberLink = "+9710505225056"; // For href (no spaces)
const phoneNumberDisplay = "+971 0505225056"; // For display (with spaces)

export default () => {
  return (
    <div>
      <StyledHeader contact={true} />
      <ContactDetails
        cards={[
          {
            title: "Dubai",
            description: (
              <>
                <Address>
                  <AddressLine>The Iridium Building, G-floor</AddressLine>
                  <AddressLine>
                    Behind Mall of Emirates, Al Barsha 1
                  </AddressLine>
                  <AddressLine>Dubai</AddressLine>
                </Address>
              </>
            ),
            email: (
              <Email>
                <StyledLink href={emailLink}>
                  support@milaajbrandset.com
                </StyledLink>
              </Email>
            ),
            phone: (
              <Phone>
                <StyledLink href={`tel:${phoneNumberLink}`}>
                  {phoneNumberDisplay}
                </StyledLink>
              </Phone>
            ),
          },
          // You can add more cards here if needed
        ]}
      />
      <ContactForm />
    </div>
  );
};
