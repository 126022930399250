// src/pages/GoogleAnalytics.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import SetupConfigurationIcon from "images/setup-configuration-icon.svg";
import CustomReportingIcon from "images/custom-reporting-icon.webp";
import DataAnalysisInsightsIcon from "images/data-analysis-insights-icon.webp";
import AnalyticsIcon from "images/google-analytics-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Google Analytics"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Google Analytics Services</Subheading>}
          heading={
            <Heading>
              Make <span tw="text-primary-500">Data-Driven Decisions</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in enhancing your website's performance through comprehensive Google Analytics services. Our expert team ensures that your data is accurately tracked and analyzed, providing you with valuable insights to drive your business growth. Whether you're looking to set up analytics from scratch or optimize your existing setup, we've got you covered.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Data-Driven Analytics Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1508672019048-805c876b67e2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in data and focused on results. We conduct thorough analyses of your website's performance, identify key metrics, and provide actionable insights to optimize your digital strategies. From setting up advanced tracking to creating custom reports, we ensure that you have a clear understanding of your analytics data to make informed decisions.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Analytics Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Analytics Services</Subheading>}
          heading="Comprehensive Analytics Solutions"
          description="MilaajBrandset offers a full suite of Google Analytics services designed to elevate your website's performance and drive measurable results. Our services are tailored to meet your unique needs, ensuring a cohesive and effective analytics strategy."
          cards={[
            {
              imageSrc: SetupConfigurationIcon,
              title: "Setup & Configuration",
              description:
                "We set up and configure Google Analytics to accurately track your website's performance.",
              url: "/google-analytics/setup-configuration",
            },
            {
              imageSrc: CustomReportingIcon,
              title: "Custom Reporting",
              description:
                "We create custom reports tailored to your specific business needs and KPIs.",
              url: "/google-analytics/custom-reporting",
            },
            {
              imageSrc: DataAnalysisInsightsIcon,
              title: "Data Analysis & Insights",
              description:
                "We analyze your data to provide actionable insights and recommendations for improvement.",
              url: "/google-analytics/data-analysis-insights",
            },
            {
              imageSrc: AnalyticsIcon,
              title: "Advanced Tracking",
              description:
                "We implement advanced tracking solutions to monitor user behavior and conversion paths.",
              url: "/google-analytics/advanced-tracking",
            },
            {
              imageSrc: AnalyticsIcon,
              title: "E-commerce Analytics",
              description:
                "We set up and manage e-commerce tracking to monitor sales performance and customer behavior.",
              url: "/google-analytics/e-commerce-analytics",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
