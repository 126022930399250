// src/pages/ContentMarketing.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import ContentStrategyIcon  from "images/content-strategy-icon.avif";
import ContentCreationIcon  from "images/content-creation-icon.avif";
import SeoOptimizationIcon  from "images/seo-optimization-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const HighlightedText = tw.span`text-customPurple`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;


const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Content Marketing"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Content Marketing</Subheading>}
          heading={
            <>
              Engage Your Audience with <HighlightedText>Compelling Content</HighlightedText>
            </>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in creating high-quality content that aligns with your brand identity and business objectives. Our expert team develops engaging and informative content that drives audience engagement, boosts SEO rankings, and fosters brand loyalty. Whether you're looking to enhance your blog, social media presence, or overall digital strategy, we have the skills and experience to deliver exceptional results.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Content Marketing Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is strategic and data-driven. We begin by understanding your business goals, target audience, and unique value propositions. From there, we develop a tailored content marketing plan that includes content creation, distribution, and performance analysis. We focus on creating valuable and relevant content that not only attracts but also retains your audience, ensuring long-term engagement and conversions.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Content Marketing Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Content Marketing Services</Subheading>}
          heading={
            <>
              Comprehensive <HighlightedText>Content Marketing Solutions</HighlightedText>
            </>
          }
          description="MilaajBrandset offers a full suite of Content Marketing services designed to elevate your online presence and engage your audience effectively. Our services are tailored to meet your unique needs, ensuring a cohesive and impactful content strategy."
          cards={[
            {
              imageSrc: ContentStrategyIcon,
              title: "Content Strategy",
              description:
                "We develop tailored content strategies that align with your business goals and target audience.",
              url: "/content-marketing/content-strategy",
            },
            {
              imageSrc:ContentCreationIcon,
              title: "Content Creation",
              description:
                "Our team produces high-quality content, including blog posts, videos, infographics, and more to engage your audience.",
              url: "/content-marketing/content-creation",
            },
            {
              imageSrc: SeoOptimizationIcon,
              title: "SEO Optimization",
              description:
                "We optimize your content for search engines to improve visibility and drive organic traffic to your website.",
              url: "/content-marketing/seo-optimization",
            },
            {
              imageSrc: ContentCreationIcon, // Optional additional feature with unique icon
              title: "Content Distribution",
              description:
                "We distribute your content across various channels to maximize reach and engagement.",
              url: "/content-marketing/content-distribution",
            },
            {
              imageSrc: SeoOptimizationIcon, // Optional additional feature with unique icon
              title: "Analytics & Reporting",
              description:
                "We provide detailed analytics and reports to measure the effectiveness of your content marketing efforts.",
              url: "/content-marketing/analytics-reporting",
            },
          ]}
          linkText="" // You can add a link if needed
        />
      </AnimationRevealPage>

      )}
