import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "Components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

const Container = styled.div`
  ${tw`relative mt-4 mb-8`}
  background-color: rgba(138, 1, 87, 0.12);
  box-shadow: 4px 8px 8px rgba(0, 0, 0, 0.2), -4px -4px 8px rgba(0, 0, 0, 0.2);
  border-bottom-right-radius: 30rem;
  @media (max-width: 768px) {
    border-bottom-right-radius: 15rem;
  }
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    padding-bottom: 10rem;
  }
`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0 `;
const DropColumn = styled(Column)`
  ${tw`md:w-5/12 flex-shrink-0 h-auto relative md:mt-0`}
  margin-top: -4rem;
  @media (max-width: 768px) {
    margin-top: 0;
    ${tw`w-full`}
  }
`;
const positionStyles = (props) =>
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`;
const TextColumn = styled(Column)((props) => [
  tw` mt-16 md:mt-0 text-left sm:px-8 py-8`,
]);

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding:1rem;
  margin: 10px 0;
`;

const Line = styled.div`
  width: 50px;
  height: 1px;
  background-color: grey;
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #800080;
  font-size: 20px;
  white-space: nowrap;
`;

const Heading = tw(
  SectionHeading
)`mt-4 p-2 font-black text-left text-3xl sm:text-4xl sm:text-left lg:text-5xl md:text-left leading-tight`;
const Description = tw.p`mt-4 p-2 text-left md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Features = styled.div`
  ${tw`mt-8 md:mt-0 flex flex-col md:flex-row items-start md:items-start`}
  gap: 2rem;
   @media (max-width: 768px) {
    ${tw`flex-row mt-24 items-start p-6`}
    gap: 1rem;
  }
  position: relative;
`;

const Feature = styled.div`
  ${tw`px-2 py-8 bg-white shadow-lg text-center md:text-left relative transition-transform duration-300 rounded-b-full flex flex-col items-center`}
  width: 16rem;
  height: ${(props) =>
    props && props.value === "feature-professional-team"
      ? "489px"
      : "379px"};
  &:hover {
    transform: translateY(-10px);
  }
  @media (max-width: 768px) {
    ${tw`w-40 mt-0`}
height: ${(props) =>
    props && props.value === "feature-professional-team"
      ? "350px"
      : "290px"};    
    margin-top: 0px;
  }
`;

const Spacer = tw.div`flex-grow`;

const SingleHangingLine = styled.div`
  ${tw`absolute top-0 left-0 w-full`}
  height: 1px;
  background-color: rgba(205, 205, 205, 1);
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 1);
  top: -1px;
  @media (max-width: 768px) {
    ${tw`flex-row items-center`}
    box-shadow: 0 0px 0px rgba(0, 0, 0, 1);
    top: 22px;
  }
`;

const FeatureText = styled.div`
  ${tw`flex flex-col text-center gap-4`}
  margin-top: auto;
  margin-bottom: 30px;
  padding: 1rem 0;
   
`;


const FeatureHeading = styled.div`${tw`font-bold text-2xl  text-black mt-4 md:mt-0`}
@media(max-width:768px){
font-size:20px
}

`;
const FeatureDescription = tw.div`mt-1 text-sm`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      We have the most{" "}
      <span tw="text-customPurple">innovative</span> and{" "}
      <span tw="text-customPurple">skilled</span> team in the industry.
    </>
  ),
  description =
    "Our team of experts excels in delivering top-notch web services that drive business growth. From web development to digital marketing, we offer comprehensive solutions to elevate your online presence.",
  primaryButtonText = "Explore Our Services",
  primaryButtonUrl = "#",
  features = null,
  textOnLeft = true,
}) => {
  const defaultFeatures = [
    {
      Icon: BriefcaseIcon,
      title: "Professional Team",
      description:
        "Our team consists of experienced professionals who are dedicated to delivering the best results for your business.",
      id: "feature-professional-team",
    },
    {
      Icon: MoneyIcon,
      title: "Cost-Effective Solutions",
      description:
        "We offer competitive pricing without compromising on quality, ensuring you get the best value for your investment.",
      id: "feature-cost-effective-solutions",
    },
  ];

  if (!features) features = defaultFeatures;

  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <SubheadingWrapper>
              <Line />
              <Text>{subheading}</Text>
              <Line />
            </SubheadingWrapper>
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
        <DropColumn>
          <Features>
            <SingleHangingLine />
            {features.map(({ title, description, id }) => (
              <Feature key={id} value={id}>
                <FeatureText>
                  <FeatureHeading>{title}</FeatureHeading>
                  <FeatureDescription>{description}</FeatureDescription>
                </FeatureText>
              </Feature>
            ))}
          </Features>
        </DropColumn>
      </TwoColumn>
    </Container>
  );
};
