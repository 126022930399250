
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "Components/misc/Headings.js";
import { SectionDescription } from "Components/misc/Typography.js";

import { ReactComponent as EmailIcon } from "images/mail.svg";
import PhoneIcon  from "images/bxs-phone.avif";

const Container = tw.div`relative mb-24 pt-32 p-4 bg-white font-poppins `;

const HeadingContainer = tw.div`text-center`;
const Heading = styled(SectionHeading)`
  ${tw`text-5xl font-bold text-center pt-8`};
  span {
    ${tw`text-customPurple`}
  }
`;

const Subheading = tw(SubheadingBase)`text-4xl w-full font-bold text-left lg:mx-40 md:mx-32 sm:mx-32 mb-4`;

const SubheadingTwo = tw(SubheadingBase)`text-4xl w-full mt-2 font-bold text-center mb-2`;


const Description = tw(SectionDescription)``;

const LocationsContainer = styled.div`
  ${tw`flex flex-col items-center  max-w-screen-lg mx-auto py-20 md:py-24`}
`;



const LocationCard = styled.div`
  ${tw`mt-8 relative pl-8`} /* Added padding to the left to make space for the line */
  margin-left: 300px; /* Default position for larger screens */
  transition: margin-left 0.5s ease; /* Smooth transition for margin-left */

  /* Create the vertical line with a round tip */
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px; /* Adjust the thickness of the line */
    background-color: #6b46c1; /* Customize the color */
    border-radius: 2px; /* Makes the line round */
  }

  /* Create the round tip on top */
  ::after {
    content: '';
    position: absolute;
    left: -4px; /* Adjust to center the round tip */
    top: -8px; /* Moves the round tip above the text */
    width: 12px; /* Width of the round tip */
    height: 12px; /* Height of the round tip */
    background-color: #6b46c1; /* Match the color of the line */
    border-radius: 50%; /* Makes it circular */
  }

  @media (max-width: 768px) { /* Adjust the max-width as needed */
    margin-left: 50px; /* Move the card to the left for smaller screens */
  }
`;

const LocationTitle = styled.h3`
  ${tw`text-xl font-bold mb-2 text-customPurple`}
`;

const LocationDescription = styled.p`
  ${tw`text-base font-medium text-gray-700`}
`;

const IconContainer = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 text-purple-700 mr-2`}
  }
`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Add this line */
  width: 100%;
  margin: 10px 0;
`;


const Text = styled.p`
  padding: 0 5px;
  color: #800080; // Adjust color as needed
  font-size: 25px; // Adjust size as needed
  white-space: nowrap;
`;

const ContactInfo = styled.p`
  ${tw`text-base font-medium text-gray-700`}
`;

export default ({ cards = null, heading = "Want to find the perfect match for your SaaS business?", subheading = "Connect with us for further business enquiries for our services", description = "Our Offices", }) => {
  const defaultCards = [
    {
      title: "Dubai",
      
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      {/* Heading Section */}
      <HeadingContainer>
        <Subheading>Say Hello...</Subheading>
        <Heading>Want to find the perfect <br /> match for your <span>SaaS business?</span></Heading>
        <Description>{subheading}</Description>
      </HeadingContainer>

      {/* Locations Section */}
      <LocationsContainer>
      <SubheadingWrapper>
                <Text>Locations</Text>
      </SubheadingWrapper>
      <SubheadingTwo>{description}</SubheadingTwo>


        {cards.map((card, i) => (
          <LocationCard key={i}>
            <LocationTitle>{card.title}</LocationTitle>
            <LocationDescription>{card.description}</LocationDescription>
            <IconContainer>
              <EmailIcon />
              <ContactInfo>{card.email}</ContactInfo>
            </IconContainer>
            <IconContainer>
              <img src={PhoneIcon} />
              <ContactInfo>{card.phone}</ContactInfo>
            </IconContainer>
          </LocationCard>
        ))}
      </LocationsContainer>
    </Container>
  );
};
