// src/pages/UsabilityTesting.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import UsabilityTestingIcon  from "images/usability-testing-icon.svg";
import TestingSessionsIcon  from "images/testing-sessions-icon.svg";
import FeedbackAnalysisIcon  from "images/feedback-analysis-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const UsabilityTesting = () => {
  return (
      <AnimationRevealPage>
        <Header from={"Usability Testing"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Usability Testing</Subheading>}
          heading={
            <Heading>
              Optimize User Experience with <span tw="text-primary-500">Usability Testing</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Start Testing"
          primaryButtonUrl="/start-testing" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1616401784845-180882ba9ba8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we perform thorough usability testing to ensure your product delivers an optimal user experience. Our comprehensive testing process helps identify and resolve usability issues, enhancing user satisfaction and engagement.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Usability Testing Services</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1616401784845-180882ba9ba8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses testing sessions, feedback collection, and feedback analysis. Our team collaborates closely with you throughout the process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Usability Testing Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Testing Process</Subheading>}
          heading="Comprehensive Usability Testing Services"
          description="We offer a range of usability testing services to evaluate and improve the user experience of your digital products."
          cards={[
            {
              imageSrc: UsabilityTestingIcon,
              title: "Testing Sessions",
              description:
                "We conduct detailed testing sessions with real users to evaluate your product's usability.",
              url: "/usability-testing/testing-sessions",
            },
            {
              imageSrc: TestingSessionsIcon,
              title: "Feedback Collection",
              description:
                "We gather feedback from users to understand their experiences and identify areas for improvement.",
              url: "/usability-testing/feedback-collection",
            },
            {
              imageSrc: FeedbackAnalysisIcon,
              title: "Feedback Analysis",
              description:
                "We analyze the collected feedback to provide actionable recommendations for enhancing usability.",
              url: "/usability-testing/feedback-analysis",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};

export default UsabilityTesting;
