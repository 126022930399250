import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; // eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "Components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "Components/misc/Buttons";
import StatsIllustrationSrc from "images/stats-illustration2.svg";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";

const Container = tw.section`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto relative`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left  font-poppins`;

const SubheadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

const Line = styled.div`
  width:30px;
  height: 1px;
  width:50px;
  background-color: grey; // Adjust color as needed
`;

const Text = styled.h2`
  padding: 0 5px;
  color: #800080; // Adjust color as needed
  font-size: 20px; // Adjust size as needed
  white-space: nowrap;
`;

const Heading = tw(
  SectionHeading
)`mt-4 text-black font-custom text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Statistics = tw.div`flex flex-col items-center sm:block text-center md:text-left mt-4`;
const Statistic = tw.div`text-left sm:inline-block sm:mr-12 last:mr-0 mt-4`;
const Value = tw.div`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide`;
const Key = tw.div`font-medium text-primary-700`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 md:mt-10 text-lg inline-block mx-auto md:mx-0 py-4`;

const DecoratorBlob = styled(SvgDotPattern)((props) => [
  tw`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-primary-500 -z-10`,
]);

export default ({
  subheading = "Our Expertise",
  heading = (
    <>
      Leading the Way in <span tw="text-customPurple"><br />Web Services</span> Since{" "}2024.
    </>
  ),
  description = "We provide cutting-edge web services to help businesses thrive in the digital age. Our expert team specializes in web development, digital marketing, SEO, and more. Join us as we revolutionize the web services industry.",
  primaryButtonText = "Get Started",
  primaryButtonUrl = "#",
  imageSrc = StatsIllustrationSrc,
  imageCss = null,
  imageContainerCss = null,
  imageDecoratorBlob = false,
  imageDecoratorBlobCss = null,
  imageInsideDiv = true,
  statistics = null,
  textOnLeft = false,
}) => {
  // const defaultStatistics = [
  //   {
  //     key: "Happy Clients",
  //     value: "500+"
  //   },
  //   {
  //     key: "Projects Completed",
  //     value: "1200+"
  //   },
  //   {
  //     key: "Awards Won",
  //     value: "50+"
  //   }
  // ];

  // if (!statistics) statistics = defaultStatistics;

  return (
    <Container>
      <TwoColumn css={!imageInsideDiv && tw`md:items-center`}>
        <ImageColumn css={imageContainerCss}>
          {imageInsideDiv ? (
            <Image
              imageSrc={StatsIllustrationSrc}
              css={imageCss}
              alt="Statistics Illustration"
            />
          ) : (
            <img src={imageSrc} css={imageCss} alt="Statistics Illustration" />
          )}
          {imageDecoratorBlob && <DecoratorBlob css={imageDecoratorBlobCss} />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && (
              <SubheadingWrapper>
                <Line />
                <Text>Our Expertise</Text>
                <Line />
              </SubheadingWrapper>
            )}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            {/* <Statistics>
              {statistics.map((statistic, index) => (
                <Statistic key={index}>
                  <Value>{statistic.value}</Value>
                  <Key>{statistic.key}</Key>
                </Statistic>
              ))}
            </Statistics> */}
            <PrimaryButton as="a" href={primaryButtonUrl}>
              {primaryButtonText}
            </PrimaryButton>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
