import React, { useState, useEffect } from "react";
import axios from "axios";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "Components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import Header from "Components/headers/light.js";
import { SectionHeading } from "Components/misc/Headings";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const HeadingRow = tw.div`flex justify-center`;
const Heading = tw(SectionHeading)`text-gray-900`;
const PostContainer = tw.div`mt-10 w-full`;
const Post = tw.div`bg-gray-100 rounded-lg`;
const Image = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-64 md:h-96 w-full bg-cover bg-center rounded-t-lg`}
`;
const Info = tw.div`p-8 border-2 border-t-0 rounded-lg rounded-t-none`;
const Category = tw.div`uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-primary-500 after:w-8`;
const CreationDate = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.h1`mt-1 font-black text-4xl text-gray-900`;
const Content = tw.div`mt-6 text-gray-600 whitespace-pre-wrap`;
const Author = tw.div`mt-6 text-sm text-gray-600`;

const SingleBlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Assuming you're using react-router and the post id is in the URL

  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`http://localhost:8000/blogs/api/posts/${id}/`);
        setPost(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading post.</div>;
  }

  if (!post) {
    return <div>Post not found.</div>;
  }

  return (
    <AnimationRevealPage>
      <Helmet>
        <title>{`${post.title} | MilaajBrandset Blog`}</title>
        <meta name="description" content={post.content.substring(0, 160)} />
        <meta name="keywords" content={`MilaajBrandset, blog, ${post.category.name}`} />
      </Helmet>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>Blog Post</Heading>
          </HeadingRow>
          <PostContainer>
            <Post>
              <Image imageSrc={post.image_url} />
              <Info>
                <Category>{post.category.name}</Category>
                <CreationDate>{new Date(post.pub_date).toLocaleDateString()}</CreationDate>
                <Title>{post.title}</Title>
                <Content>{post.content}</Content>
                <Author>Author: {post.author}</Author>
              </Info>
            </Post>
          </PostContainer>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};

export default SingleBlogPost;