// src/pages/WireframingPrototyping.js

import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColSimple.js";

// Importing SVG Icons as React Components
import WireframingIcon  from "images/wireframing-icon.svg";
import PrototypingIcon  from "images/prototyping-icon.svg";
import InteractivePrototypesIcon  from "images/interactive-prototypes-icon.svg";

const HighlightedText = tw.span`text-customPurple`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const WireframingPrototyping = () => {
  return (
      <AnimationRevealPage>
        <Header from={"Wireframing & Prototyping"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Wireframing & Prototyping</Subheading>}
          heading={
            <Heading>
              Visualize Your <span tw="text-primary-500">Product</span> with Wireframing & Prototyping
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Start Your Project"
          primaryButtonUrl="/start-your-project"
          imageSrcs={[
            "https://images.unsplash.com/photo-1559028012-481c04fa702d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we create detailed wireframes and interactive prototypes to help you visualize and test your product before full development. Our approach ensures that your product meets user needs and expectations from the very beginning.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`}
          imageStyle={`width: 45%; height: 60%;`}
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]}
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Wireframing & Prototyping Services</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach"
          imageSrcs={[
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1559028012-481c04fa702d?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses wireframing, prototyping, and interactive testing. Our team collaborates closely with you throughout the process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`}
          imageStyle={`width: 45%; height: 60%;`}
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]}
        />

        {/* Our Wireframing & Prototyping Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Process</Subheading>}
          heading={
            <>
              Effective <HighlightedText>Wireframing & Prototyping</HighlightedText> Services
            </>
          }
          description="We provide comprehensive wireframing and prototyping services to bring your product ideas to life, ensuring functionality and user satisfaction."
          cards={[
            {
              imageSrc: WireframingIcon,
              title: "Wireframing",
              description:
                "We create detailed wireframes to plan and structure your product's layout and functionality.",
              url: "/wireframing-prototyping/wireframing",
            },
            {
              imageSrc: PrototypingIcon,
              title: "Prototyping",
              description:
                "We develop interactive prototypes to test and refine your product's user experience.",
              url: "/wireframing-prototyping/prototyping",
            },
            {
              imageSrc:InteractivePrototypesIcon,
              title: "Interactive Prototypes",
              description:
                "We build interactive prototypes to simulate the final product, allowing for thorough testing and feedback.",
              url: "/wireframing-prototyping/interactive-prototypes",
            },
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};

export default WireframingPrototyping;
