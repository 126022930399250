// src/pages/InventoryManagement.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import StockTrackingIcon  from "images/stock-tracking-icon.svg";
import OrderManagementIcon  from "images/order-management-icon.svg";
import AnalyticsIcon  from "images/analytics-icon.webp";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

const InventoryManagement = () => {
  return (
      <AnimationRevealPage>
        <Header from={"Inventory Management Solutions"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Inventory Solutions</Subheading>}
          heading={
            <Heading>
              Streamline Your <span tw="text-primary-500">Inventory</span> Management
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1616401784845-180882ba9ba8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in providing comprehensive inventory management solutions. Our expert team ensures that your stock is tracked accurately, orders are managed efficiently, and you gain valuable insights through detailed analytics.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Inventory Management Solutions</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1515378791036-0648a3ef77b2?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1529333166437-7750a6dd5a70?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1616401784845-180882ba9ba8?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses stock tracking, order management, and data-driven analytics. Our team collaborates closely with you throughout the process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Inventory Management Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Inventory Services</Subheading>}
          heading={
            <Heading>
              Comprehensive <span tw="text-primary-500">Inventory Management</span> Solutions
            </Heading>
          }
          description="We offer a range of services designed to help you manage your inventory effectively, ensuring you have the right stock at the right time and place."
          cards={[
            {
              imageSrc: StockTrackingIcon,
              title: "Stock Tracking",
              description:
                "Keep track of your stock levels with our real-time tracking solutions, ensuring you never run out of critical items.",
              url: "/inventory-management/stock-tracking",
            },
            {
              imageSrc:OrderManagementIcon,
              title: "Order Management",
              description:
                "Manage orders efficiently from start to finish, ensuring timely fulfillment and customer satisfaction.",
              url: "/inventory-management/order-management",
            },
            {
              imageSrc: AnalyticsIcon,
              title: "Analytics",
              description:
                "Gain valuable insights into your inventory with detailed analytics, helping you make informed decisions to optimize your stock levels.",
              url: "/inventory-management/analytics",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};

export default InventoryManagement;
