// src/pages/APIMaintenanceSupport.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import RegularUpdatesIcon  from "images/regular-updates-icon.svg";
import Support247Icon  from "images/247-support-icon.webp";
import PerformanceOptimizationIcon  from "images/performance-optimization-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"API Maintenance and Support"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>API Maintenance & Support</Subheading>}
          heading={
            <Heading>
              Keeping Your APIs <span tw="text-primary-500">Running Smoothly</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Contact Us"
          primaryButtonUrl="/contact" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              MilaajBrandset provides comprehensive API maintenance and support services to ensure your APIs remain up-to-date, secure, and performing optimally. Our team of experts is always ready to assist you with any issues or updates, allowing you to focus on your core business operations.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive API Maintenance Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is proactive and meticulous. We perform regular system checks, apply necessary updates, and monitor your APIs continuously to prevent issues before they arise. From routine maintenance tasks to emergency support, our team ensures your APIs operate seamlessly and efficiently.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our API Maintenance Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Services</Subheading>}
          heading="Comprehensive API Care"
          description="We offer a range of services to keep your APIs running at peak performance."
          cards={[
            {
              imageSrc:RegularUpdatesIcon,
              title: "Regular Updates",
              description:
                "We keep your APIs up-to-date with the latest standards and security patches.",
              url: "/api-maintenance-support/regular-updates",
            },
            {
              imageSrc: Support247Icon,
              title: "24/7 Support",
              description:
                "Our team is available round the clock to address any issues or concerns.",
              url: "/api-maintenance-support/247-support",
            },
            {
              imageSrc: PerformanceOptimizationIcon,
              title: "Performance Optimization",
              description:
                "We continuously monitor and optimize your APIs for peak performance.",
              url: "/api-maintenance-support/performance-optimization",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
