// src/pages/PerformanceOptimization.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import SpeedOptimizationIcon  from "images/speed-optimization-icon.svg";
import SeoImprovementIcon  from "images/seo-improvement-icon.svg";
import ConversionBoostIcon  from "images/conversion-boost-icon.avif";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;
  
export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Performance Optimization"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Performance Optimization</Subheading>}
          heading={
            <Heading>
              Boost Your Website's <span tw="text-primary-500">Speed and Efficiency</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Optimize Now"
          primaryButtonUrl="/optimize-now" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we optimize your website's performance to enhance user experience, improve SEO rankings, and drive conversions. Our expert team employs cutting-edge techniques to ensure your site loads quickly and operates smoothly, providing your visitors with a seamless browsing experience.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Performance Enhancement</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is multi-faceted, focusing on both frontend and backend optimizations. We conduct thorough performance audits, identify bottlenecks, and implement solutions that enhance speed and efficiency. From image optimization and code minification to server response improvements and caching strategies, we leave no stone unturned in boosting your website's performance.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Performance Optimization Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Approach</Subheading>}
          heading="Comprehensive Performance Enhancement"
          description="We use a multi-faceted approach to optimize your website's performance across all metrics."
          cards={[
            {
              imageSrc: <SpeedOptimizationIcon className="w-8 h-8 text-primary-500" />,
              title: "Speed Optimization",
              description:
                "We employ various techniques to minimize load times and ensure your website responds quickly to user interactions.",
              url: "/performance-optimization/speed-optimization",
            },
            {
              imageSrc: <SeoImprovementIcon className="w-8 h-8 text-primary-500" />,
              title: "SEO Improvement",
              description:
                "Our optimization efforts contribute to better search engine rankings, increasing your site's visibility.",
              url: "/performance-optimization/seo-improvement",
            },
            {
              imageSrc: <ConversionBoostIcon className="w-8 h-8 text-primary-500" />,
              title: "Conversion Boost",
              description:
                "Faster, smoother performance leads to better user experience, which in turn drives higher conversion rates.",
              url: "/performance-optimization/conversion-boost",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
