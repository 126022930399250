// src/pages/APISecurity.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

import DataEncryptionIcon from "images/data-encryption-icon.webp";
import AuthenticationAuthorizationIcon from "images/authentication-authorization-icon.avif";
import ContinuousMonitoringIcon from "images/continuous-monitoring-icon.avif";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"API Security"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>API Security</Subheading>}
          heading={
            <Heading>
              Robust <span tw="text-primary-500">Security</span> for Your APIs
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/learn-more" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we prioritize the security of your APIs. Our comprehensive security measures protect your data, ensure safe transactions, and safeguard against potential threats and breaches. Whether you're developing new APIs or securing existing ones, our expert team is here to provide top-notch security solutions tailored to your needs.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive API Security Strategies</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1563986768609-322da13575f3?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1551434678-e076c223a692?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
            "https://images.unsplash.com/photo-1569025690938-a00729c9e1f9?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
          ]}
          description={
            <Description>
              Our approach is rooted in industry best practices and tailored to your specific API requirements. We perform thorough security assessments, implement advanced encryption methods, and establish robust authentication protocols to ensure your APIs are secure from all angles. Continuous monitoring and proactive threat detection are integral to our strategy, providing you with peace of mind and reliable API performance.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our API Security Features Section */}
        <StyledFeatures
          subheading={<Subheading>Security Features</Subheading>}
          heading="Multi-layered API Protection"
          description="We implement industry-leading security protocols to keep your APIs and data safe."
          cards={[
            {
              imageSrc: DataEncryptionIcon,
              title: "Data Encryption",
              description:
                "We use advanced encryption techniques to protect your data both in transit and at rest.",
              url: "/api-security/data-encryption",
            },
            {
              imageSrc: AuthenticationAuthorizationIcon,
              title: "Authentication & Authorization",
              description:
                "Robust authentication and authorization mechanisms ensure only authorized access to your APIs.",
              url: "/api-security/authentication-authorization",
            },
            {
              imageSrc: ContinuousMonitoringIcon,
              title: "Continuous Monitoring",
              description:
                "Our systems continuously monitor for potential security threats and anomalies.",
              url: "/api-security/continuous-monitoring",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>
  );
};
