// src/pages/PaymentIntegrationSolutions.js

import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import Header from "Components/headers/PageHeader";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Features from "Components/Features/ThreeColumnPages";

// Importing SVG Icons as React Components
import MultipleGatewaysIcon  from "images/multiple-gateways-icon.svg";
import SecureTransactionsIcon  from "images/secure-transactions-icon.svg";
import SeamlessExperienceIcon  from "images/seamless-experience-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Heading = tw.h1`text-2xl md:text-4xl lg:text-5xl font-bold`;
const Description = tw.p`text-base md:text-lg lg:text-xl`;

const StyledFeatures = styled(Features)`
  ${tw`mb-20`}

  .feature {
    ${tw`cursor-pointer hover:shadow-lg transition duration-300`}
  }
`;

export default () => {
  return (
      <AnimationRevealPage>
        <Header from={"Payment Integration Solutions"} />

        {/* Main Feature Section */}
        <MainFeature1
          subheading={<Subheading>Payment Integration Solutions</Subheading>}
          heading={
            <Heading>
              Seamless Payment Gateway Integration for Your <span tw="text-primary-500">Business</span>
            </Heading>
          }
          buttonRounded={false}
          primaryButtonText="Get Started"
          primaryButtonUrl="/get-started" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              At MilaajBrandset, we specialize in integrating multiple payment gateways to provide flexible, secure, and seamless payment solutions for your e-commerce platform. Our expert team ensures that your customers have a smooth and reliable payment experience, enhancing trust and boosting conversions.
            </Description>
          }
          widths={["55%", "60%", "70%"]}
          heights={["45%", "60%", "35%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 35%; top: 20%;",
            "right: 30%; top: 10%;",
            "left: 32%; bottom: 20%;",
          ]} // Custom positions for each image
          textOnLeft={false}
        />

        {/* Our Approach Section */}
        <MainFeature1
          subheading={<Subheading>Our Approach</Subheading>}
          heading={<Heading>Comprehensive Payment Integration Services</Heading>}
          buttonRounded={false}
          primaryButtonText="Learn More"
          primaryButtonUrl="/our-approach" // URL for the button
          imageSrcs={[
            "https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1563013544-824ae1b704d3?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
            "https://images.unsplash.com/photo-1526948128573-703ee1aeb6fa?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80",
          ]}
          description={
            <Description>
              Our approach is meticulous and client-focused. We begin by understanding your business goals, target audience, and unique requirements. From there, we design a strategic plan that encompasses multiple gateway integrations, secure transaction implementations, and seamless user experiences. Our team collaborates closely with you throughout the integration process, providing regular updates and incorporating your feedback to achieve the best possible outcome.
            </Description>
          }
          textOnLeft={true}
          widths={["50%", "55%", "70%"]}
          heights={["60%", "70%", "40%"]}
          imageColumnStyle={`height: 400px;`} // Adjusting height as required
          imageStyle={`width: 45%; height: 60%;`} // Custom styles for images
          imagePositions={[
            "left: 13%; top: 20%;",
            "right: 0; top: 10%;",
            "left: 10%; bottom: 0;",
          ]} // Custom positions for each image
        />

        {/* Our Payment Integration Services Section */}
        <StyledFeatures
          subheading={<Subheading>Our Approach</Subheading>}
          heading="Comprehensive Payment Integration Services"
          description="We follow a meticulous process to ensure your payment integration meets the highest standards of security and user experience."
          cards={[
            {
              imageSrc: MultipleGatewaysIcon,
              title: "Multiple Gateways",
              description:
                "We integrate various payment gateways, offering your customers flexibility in payment options and ensuring you never miss a sale.",
              url: "/payment-integration-solutions/multiple-gateways",
            },
            {
              imageSrc: SecureTransactionsIcon,
              title: "Secure Transactions",
              description:
                "Our solutions implement robust security measures to protect sensitive financial data and ensure safe transactions for your customers.",
              url: "/payment-integration-solutions/secure-transactions",
            },
            {
              imageSrc: SeamlessExperienceIcon,
              title: "Seamless Experience",
              description:
                "We create a smooth, intuitive payment process that enhances the overall user experience and increases conversion rates.",
              url: "/payment-integration-solutions/seamless-experience",
            },
            // Add more services if needed
          ]}
          linkText=""
        />
      </AnimationRevealPage>

  );
};
