import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/PageHeader';
import Features from "Components/Features/ThreeColumnPages";

import UI1 from 'images/UI-1.avif'
import UI2 from 'images/UI-2.avif'
import UI3 from 'images/UI-3.avif'
import UI4 from 'images/UI-4.avif'
import UI5 from 'images/UI-5.avif'
import UI6 from 'images/UI-6.avif'

import UserResearchIconImage from "images/ui_analysis.svg";
import PrototypingIconImage from "images/play-box-multiple-outline.svg";
import UsabilityTestingIconImage from "images/cib-open-collective.avif";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
  <Header from={"UI/UX Design"} />
  <MainFeature1
    subheading={<Subheading>UI/UX Design Services</Subheading>}
    heading="Create Exceptional User Experiences with MilaajBrandset"
    buttonRounded={false}
    primaryButtonText="Start Your Design Project"
    imageSrcs={[
     UI1,
     UI2,
     UI3
    ]}
    description="At MilaajBrandset, we specialize in crafting unique and engaging user interfaces that enhance the user experience. Our team of experts uses the latest design principles and technologies to ensure that your digital products are not only visually appealing but also highly functional."
    widths={["65%", "90%", "50%"]} 
    heights={["45%", "50%", "45%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: -15%; top: 5%;",
      "right: 20%; top: 30%;",
      "left: 35%; bottom: 0%;",
    ]}
  />
  <MainFeature1
    subheading={<Subheading>Our Design Philosophy</Subheading>}
    heading="User-Centered Design Approach"
    buttonRounded={false}
    primaryButtonText="Learn More"
    imageSrcs={[
      UI4,
      UI5,
      UI6
    ]}
    description="Our user-centered design philosophy focuses on understanding the needs and behaviors of your target audience. By placing the user at the core of our design process, we create intuitive and accessible interfaces that drive engagement and satisfaction. Learn more about how we can help you create digital products that resonate with your users."
    textOnLeft={true}
    widths={["65%", "70%", "70%"]} 
    heights={["70%", "50%", "50%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: -10%; top: 20%;",
      "left: 10%; bottom: 0;",
    ]}
  />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive UI/UX Design Solutions"
        description="MilaajBrandset offers end-to-end UI/UX design services to create intuitive, engaging, and effective digital experiences that delight your users and drive your business goals."
        cards={[
          {
            imageSrc: UserResearchIconImage,
            title: "User Research & Analysis",
            description: "We conduct in-depth user research to understand your target audience and their needs, informing our design decisions.",
            // url:'/research-&-analysis'

          },
          {
            imageSrc: PrototypingIconImage,
            title: "Wireframing & Prototyping",
            description: "We create interactive wireframes and prototypes to visualize and test your product before full development.",
            // url:'/wireframing-&-prototyping'
          },
          {
            imageSrc: UsabilityTestingIconImage,
            title: "Usability Testing",
            description: "We perform thorough usability testing to ensure your product delivers an optimal user experience.",
            // url:'/usability-testing'
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};