import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import MainFeature1 from "Components/Features/TwoColWithButton.js";
import Header from 'Components/headers/PageHeader'
import Features from "Components/Features/ThreeColumnPages";

import WEB1 from "images/WEB-1.avif"
import WEB2 from "images/WEB-2.avif"
import WEB3 from "images/WEB-3.avif"
import WEB4 from "images/WEB-4.avif"
import WEB5 from "images/WEB-5.avif"
import WEB6 from "images/WEB-6.avif"


import WebDevIconImage from "images/bxs-customize.avif";
import ResponsiveIconImage from "images/responsive.svg";
import PerformanceIconImage from "images/performance.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  return (
    <AnimationRevealPage>
  <Header from={"Web Development"} />
  <MainFeature1
    subheading={<Subheading>Web Development Experts</Subheading>}
    heading="Cutting-Edge Web Solutions for Your Business"
    buttonRounded={false}
    primaryButtonText="Get Started"
    imageSrcs={[
      WEB1,
      WEB2,
      WEB3
    ]}
    description="Unlock the potential of your business with our innovative web development services. At MilaajBrandset, we design and develop custom websites that are tailored to your specific needs, ensuring a strong online presence and superior user experience."
    widths={["70%", "70%", "70%"]} 
    heights={["50%", "50%", "50%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: 30%; top: 55%;",
      "left: 30%; bottom: 20%;",
    ]}
  />
  <MainFeature1
    subheading={<Subheading>Our Approach</Subheading>}
    heading="User-Centric Web Development"
    buttonRounded={false}
    primaryButtonText="Learn More"
    imageSrcs={[
      WEB4,
      WEB5,
      WEB6
    ]}
    description="Our web development approach is centered on the user. We focus on creating intuitive, engaging, and accessible websites that provide an exceptional user experience. By leveraging the latest technologies and best practices, we ensure your website not only looks great but also performs flawlessly."
    textOnLeft={true}
    widths={["70%", "70%", "70%"]} 
    heights={["50%", "50%", "50%"]} 
    imageColumnStyle={`height: 400px;`}
    imageStyle={`width: 45%; height: 60%;`}
    imagePositions={[
      "left: 0%; top: 0%;",
      "right: -10%; top: 20%;",
      "left: 10%; bottom: 0;",
    ]}
  />
      <Features
        subheading={<Subheading>Our Services</Subheading>}
        heading="Comprehensive Web Development"
        description="MilaajBrandset offers end-to-end web development services to create powerful, scalable, and user-friendly websites that drive your business growth. Our team of experts works closely with you to deliver customized solutions that meet your specific needs."
        cards={[
          {
            imageSrc: WebDevIconImage,
            title: "Custom Web Development",
            description: "We create tailor-made websites that perfectly align with your brand identity and business objectives. Our custom solutions are designed to engage your audience and drive conversions.",
            // url:"/custom-web-development"
          },
          {
            imageSrc: ResponsiveIconImage,
            title: "Responsive Design",
            description: "Our websites are built to provide an optimal viewing experience across a wide range of devices, ensuring your site looks and functions perfectly on desktops, tablets, and smartphones.",
            // url:"/responsive-web-design"
          },
          {
            imageSrc: PerformanceIconImage,
            title: "Performance Optimization",
            description: "We ensure your website loads quickly and operates smoothly to enhance user experience and SEO rankings. Our performance optimization services help you retain visitors and improve your search engine visibility.",
            // url:"/performance-optimization"
          },
        ]}
        linkText=""
      />
    </AnimationRevealPage>
  );
};
